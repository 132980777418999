const Timer = {
  /**
   * 一个倒计时器 
   * @param {Function} func 需要执行的方法体
   * @return {Number} siv
   */
  downer(dateString, func){
    return setInterval(()=>{
      func && func(this.lapse(dateString))
    }, 1000)
  },
  /**
   * 到指定时间还有多久
   * @param {String} datetime 一个符合 RFC2822 或 ISO 8601 日期格式的字符串
   * @return {Object} \{d: '天', h: '时', m: '分', s: '秒'}
   */
  lapse(dateString = '2022-04-03T00:00+08:00') {
    let ss = ((new Date(dateString)).getTime() - (new Date()).getTime())/1000
    let timer = {d: 0, h: 0, m: 0, s: 0}
    if(ss > 0){
      timer ={
        d: Math.floor(ss/(60*60*24)), // 日
        h: Math.floor(ss/(60*60)%24), // 时
        m: Math.floor(ss/60%60),      // 分
        s: Math.floor(ss%60),         // 秒
      }
    }
    Object.keys(timer).forEach((k)=>{
      (timer[k].toString().length - 1) || (timer[k] = `0${timer[k]}`);
    })
    return timer
  }
}

export default Timer