<template lang="pug">
div#lts-pk.small.bold
  div.score.flex.mod.small(v-if="questions[current]")
    div.grow 本团组还有<span class="txt red">{{inf.no_question_people}}</span>人未参赛 平均得分<span class="txt red">{{inf.avg_team_credit}}</span>分
    div.flex
      h4 当前成绩
      div.icon-small.margin._0_5(v-for="i in scores" :class="i?'right':'wrong'")
  div.blue_red_line
  div.timer.right.mod(v-if="questions[current]") 倒计时：<span class="txt red">{{timer.h}}:{{timer.m}}:{{timer.s}}</span>
  div.mod.exam(v-if="questions[current]")
    h5.margin {{questions[current].isMultiple?'多':'单'}}选题
    div.question.margin {{questions[current].eqname}}
    ul.answers
      li.flex.start.margin(v-for="(n,i) in questions[current].answer_list" @click="_check(i)")
        div.id {{['A','B','C','D'][i]}}
        div.grow {{n.answer_con}}
        div.icon-noraml.right(:class='{uncheck: !as[i]}')
  div.result.mod(v-if="questions[current]")
    div.ing.right.txt.red(v-if="state == 0")
      div: span(@click="_commit") 提交答案
    div.finish(v-else-if="state == 1")
      div.flex.txt.red
        div.grow.txt(:class="currentScore? 'blue': 'red'") {{currentScore? '正确': '错误'}}
        div.a(@click="_next") 下一题
      div(v-if="!currentScore")
        | 本题正确答案：
        span(v-for="a,i in questions[current].as") {{a / 1 ? ['A','B','C','D'][i] : ''}}
    div.ing.right.txt.blue(v-else)
      div: span.a 答题完毕
</template>

<script>
import timer from '@/assets/script/timer'
import exam from '@/mixins/exam'
export default {
  mixins: [exam],
  data(){return{
    timer: {h: '00', m: '00', s: '00'},
    inf: {no_question_people: 0, avg_team_credit: 0},
    siv: 0
  }},
  methods: {
  },
  mounted(){
    this.questionFactory('ltsPK').then((res)=>{
      let {no_question_people, avg_team_credit} = res
      this.inf.no_question_people = no_question_people
      this.inf.avg_team_credit = avg_team_credit
      this.siv = timer.downer(res.week_surplus_timeT,(res)=>{// 保持和服务器规定的测试时间一致
        let {h, m, s} = res
        this.timer = {h, m, s}
      })
    })
  },
  beforeUnmount(){
    clearInterval(this.siv)
  },
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/spacing.less";
#lts-pk{
  .score{
    zoom: 0.9;
    span{
      .__spacing(padding, 0, 3);
    }
  }
}
</style>