export default {
  data(){return {
    as: [],
    state: 0, // 0: 答题中 1: 批改 2: 完成
    questions: [], // 所有问题
    scores: [], // 本轮答题情况
    current: 0, // 当前题目索引
    currentScore: 0, // 当前题目是否正确
    reqUri: '', // 请求uri
  }},
  methods: {
    _check(i){
      if(this.questions[this.current].isMultiple){
        this.as[i] = this.as[i]? 0 : 1
      }else{
        this.initAs()
        this.as[i] = 1
      }
    },
    _commit(){// 提交答案
      if(this.as.includes(1)){
        this.state = 1
        let as = this.questions[this.current].as
        this.currentScore = as.join('') == this.as.join('') ? 1 : 0
        this.scores.push(this.currentScore)
      }else{
        this.$store.commit('tipShow', '请作答后提交')
      }
    },
    _next(){
      if(this.current == this.questions.length - 1){
        this.state = 2
        let right = this.scores.join('').replace('0', '').length
        let wrong = this.scores.length - right
        if(this.finishExam){
          this.finishExam(right, wrong)
        }else{
          this.$req[this.reqUri+'Finish']({yes_num: right, wrong_num: wrong},(msg)=>{
            this.$store.commit('tipShow', msg)
          })
        }
        this.current++ // 用来关闭当前页面显示的题目
      }else{
        this.current++
        this.initAs()
        this.state = 0
      }
    },
    initAs(){
      this.as = Array(this.questions[this.current].as.length).fill(0)
    },
    questionFactory(uriFunc){
      return new Promise((resolve, reject)=>{
        this.reqUri = uriFunc
        this.$req[uriFunc]((res)=>{
          let {answer_question_num, questionArr} = res
          if(answer_question_num / 1){
            questionArr.forEach(q=>{
              let as = []
              let n = 0
              q.answer_list.forEach(a=>{
                as.push(a.is_right)
                a.is_right / 1 && n++
              })
              q.as = as
              q.isMultiple = n > 1
            })
            this.questions = questionArr
            this.initAs()
            resolve(res)
          }else{
            this.$store.commit('tipShow', {msg: res.tip || '题目已完成', duration: 5000})
          }
        })
      })
    },
  }
}